export type Validator<T = string> = (v: T | undefined) => (string | undefined) | Promise<string | undefined>
const isFunction = (value: any) => typeof value === 'function';
const isEmpty = (v: string | undefined) => v === undefined || v === '' || v === null;
export const validateRequired: Validator = (v) => v === undefined ? 'Required': undefined;
export const validateRegex = (regex: RegExp, message: string): Validator => {
    return (v) => {
        if (v === undefined) {
            return message;
        }
        if (!regex.test(v)) {
            return message;
        }
        return undefined;
    }
}
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
export const validateEmail: Validator = validateRegex(EMAIL_REGEX, 'Must be an email address');
interface PasswordCondition {
    text: string;
    satisfied: boolean;
}
export const validatePhone: () => Validator = () => {
    const lengthValidator = validateLength(6, 30);
    return (phoneNumber: string | undefined) => {
        const invalidMessage = 'Please enter a valid phone number. Do not include the initial 0.';
        if (!phoneNumber) {
            return invalidMessage;
        }
        if (phoneNumber.trim().startsWith('+0')) {
            return invalidMessage;
        }
        const phoneNumberWithoutFormatting = phoneNumber.replace(/[^0-9+]/g, '');
        return lengthValidator(phoneNumberWithoutFormatting);
    }
}
export function checkPasswordHelper(password: string): PasswordCondition[] {
    return [
        {
            text: 'Password must contain a lower case letter',
            satisfied: /[a-z]/.test(password),
        },
        {
            text: 'Password must contain a upper case letter',
            satisfied: /[A-Z]/.test(password),
        },
        {
            text: 'Password must contain a number',
            satisfied: /[0-9]/.test(password),
        },
        {
            text: 'Password must contain a special character',
            satisfied: /[-!$%^&*()_|~`{}\[\]:\/;<>?,.@#'"\\]/.test(password),  // eslint-disable-line no-useless-escape
        },
        {
            text: 'Password must contain at least 8 characters',
            satisfied: password.length >= 8,
        },
    ];
}
export const validatePassword: Validator = (v) => {
    if (v === undefined || isEmpty(v)) {
        v = '';
    }
    const conditions = checkPasswordHelper(v);
    const success = conditions.every((c) => c.satisfied);
    if (success) {
        return undefined;
    }
    return 'Password does not satisfy all conditions';
}
export const validateMinLength = (minLength: number): Validator => {
    return (v) => !v || v.length < minLength ? `Must be longer than ${minLength} characters` : undefined;
}
export const validateMaxLength = (maxLength: number): Validator => {
    return (v) => !v ? undefined : v.length > maxLength ? `Must be shorter than ${maxLength} characters` : undefined;
}
export const validateLength = (minLength: number, maxLength: number): Validator => {
    return composeValidators(validateMinLength(minLength), validateMaxLength(maxLength));
}

export const validateTrue = (message: string): Validator<boolean> => {
    return (value: boolean | undefined) => value === true ? undefined : message;
}

export const validateEqualTo = (expectedValue: string): Validator => {
    return (v) => v !== expectedValue ? `Must be equal to ${expectedValue}` : undefined
}

// Compose multiple validators into a single one for use with final-form
export const composeValidators = (...validators: Validator[]): Validator => (
    async (value) => {
        const allValidators = validators.filter(isFunction);

        for (const validator of allValidators) {
            const error = await validator(value);

            if (error) {
                return error;
            }
        }
    }
);
