import {Auth} from "@aws-amplify/auth";
import {PippaFormData, BasicPippaFormData} from "./types";
import {configureAsync} from "../config";
import {FORM_ERROR, FormApi} from 'final-form';

export const handleBasicSignUp = async<FormValues, InitialFormValues> (data: BasicPippaFormData, form: FormApi<FormValues, InitialFormValues>) => {
    return {[FORM_ERROR]: 'Form not yet ready for public use. Submissions will not succeed.'};
/*
    try {
        const config = await configureAsync();

        const response = await fetch(`${config.INTERNAL_API_BASE_URL}/interested`, {
            method: 'POST',
            body: new URLSearchParams(data)
        })

        if (response.ok) {
            // Undefined return indicates success to react-final-form
            return undefined;
        }
        return {[FORM_ERROR]: "Failed to submit data"};
    } catch (e) {
        console.error(e);
        return {[FORM_ERROR]: "Failed to submit data"};
    }

 */
}

export const handleSignUp = async (data: PippaFormData) => {
    // Make sure Auth is configured before doing anything
    try {
        await configureAsync();
    } catch (e) {
        return {[FORM_ERROR]: 'Page configuration error'}
    }
    try {
        await Auth.signUp({
            username: data.email,
            password: data.password,
            attributes: {
                phone_number: data.phoneNumber.replace(/[^0-9+]/g, ''),
                given_name: data.givenName,
                family_name: data.familyName,
            },
            clientMetadata: {
                address: JSON.stringify({
                    line1: data.address.line1,
                    line2: data.address.line2 || null,
                    line3: data.address.line3 || null,
                    city: data.address.city || null,
                    region: data.address.region || null,
                    postalCode: data.address.postalCode,
                    countryIso2: data.address.countryIso2,
                }),
                cohortIds: data.cohortIds,
            }
        })
        // Undefined return indicates success to react-final-form
        return undefined;
    } catch (e) {
        let message: string = e.message;
        message = message.replace('PreSignUp failed with error ', '');
        message = message.replace(/\.\.$/, '.');
        return {[FORM_ERROR]: message};
    }
}