import Auth from '@aws-amplify/auth';
import React from "react";

export interface WebClientConfig {
    USER_POOL_ID: string
    USER_POOL_PRE_AUTH_CLIENT_ID: string
    AWS_REGION: string
    PIPPA_WEBSITE_URL: string
    PIPPA_ACCOUNT_URL: string
    INTERNAL_API_BASE_URL: string
}

export const configureAsync = function() {
    let configPromise: Promise<WebClientConfig> | null = null;

    return async () => {
        if (configPromise === null) {
            configPromise = fetch('/config.json').then(res => res.json());
        }
        const config = await configPromise
        Auth.configure({
            // REQUIRED - Amazon Cognito Region
            region: config.AWS_REGION,
            // OPTIONAL - Amazon Cognito User Pool ID
            userPoolId: config.USER_POOL_ID,
            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolWebClientId: config.USER_POOL_PRE_AUTH_CLIENT_ID,
        })
        return config;
    }
}();

export const blankConfig: WebClientConfig = {
    USER_POOL_ID: '',
    USER_POOL_PRE_AUTH_CLIENT_ID: '',
    AWS_REGION: '',
    PIPPA_WEBSITE_URL: '',
    PIPPA_ACCOUNT_URL: '',
    INTERNAL_API_BASE_URL: '',
}
export const ConfigContext = React.createContext<WebClientConfig>(blankConfig);
