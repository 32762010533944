import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import {GeneralSignUp, SagaSignUp, SagaCompetitionSignUp} from "./components/SignUp";
import NotFound from "./components/NotFound";
import {blankConfig, configureAsync, WebClientConfig, ConfigContext} from "./config";


function App() {
    const [config, setConfig] = React.useState<WebClientConfig>(blankConfig)
    React.useEffect(() => {
        configureAsync().then((config => setConfig(config)));
    }, [])

    return (
        <ConfigContext.Provider value={config}>
            <Router>
                <Switch>
                    <Route path={'/saga'} exact={true}>
                        <SagaSignUp />
                    </Route>
                    <Route path={'/saga-competition'} exact={true}>
                        <SagaCompetitionSignUp />
                    </Route>
                    <Route path={'/'} exact={true}>
                        <GeneralSignUp />
                    </Route>
                    <Route path={'*'}>
                        <NotFound />
                    </Route>
                </Switch>
            </Router>
        </ConfigContext.Provider>
    );
}

export default App;
