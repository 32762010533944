import qs from 'querystring'
import Button from '@material-ui/core/Button'
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import React from 'react';
import {Form} from 'react-final-form'
import {
    composeValidators,
    validateRegex,
    validateRequired,
    validateLength,
    validateMaxLength,
    validateEmail,
    validatePassword,
    validateTrue,
    validatePhone, validateEqualTo,
} from './validation'
import PippaFormField from "./PippaFormField";
import {handleBasicSignUp, handleSignUp} from "./auth";
import Layout, {LayoutProps} from "./Layout";
import SubmitError from "./SubmitError";
import SubmitSuccess from "./SubmitSuccess";
import Link from "@material-ui/core/Link";
import createDecorator from 'final-form-focus'
import {PippaFormData} from "./types";
import {ConfigContext} from "../config";
import CountrySelector from "./CountrySelector";
import MuiPhoneNumber from "material-ui-phone-number";
import SubmitSuccessBasic from "./SubmitSuccessBasic";

const focusOnErrors = createDecorator<PippaFormData>()
const formDecorators = [focusOnErrors];

type SignUpFormProps = {
    isBasicForm?: boolean
    showCountry?: boolean
    defaultCountryIso2?: string
    regionLabel?: string
    postalCodeLabel?: string
    instructionText: string
    smallPrint: string
    cohortIds: string[]
    submitButtonText?: string
}


type GenericSignUpProps = SignUpFormProps & {
    layoutProps?: LayoutProps
}
const GenericSignUp: React.FC<GenericSignUpProps> = ({layoutProps, ...signUpFormProps}) => {
    return (
        <Layout {...layoutProps}>
            <Form
                onSubmit={signUpFormProps.isBasicForm ? handleBasicSignUp : handleSignUp}
                decorators={formDecorators}
                validate={() => undefined}
                render={({handleSubmit, submitting, submitError, submitSucceeded}) => {
                    if (submitSucceeded) {
                        return signUpFormProps.isBasicForm ? <SubmitSuccessBasic/> : <SubmitSuccess />;
                    } else {
                        return (
                            <Box onSubmit={handleSubmit} component={'form'}>
                                <FormContent submitting={submitting} {...signUpFormProps} />
                                <SubmitError submitError={submitError}/>
                            </Box>
                        )
                    }
                }}
            />
        </Layout>
    )
}


const emailFieldValidator = composeValidators(validateRequired, validateEmail);
const passwordFieldValidator = validatePassword;
const nameFieldValidator = composeValidators(validateRequired, validateLength(1, 64));
const phoneNumberFieldValidator = composeValidators(validateRequired, validatePhone());
const addressLine1FieldValidator = composeValidators(validateRequired, validateLength(1, 200));
const addressLine2FieldValidator = validateMaxLength(200);
const addressLine3FieldValidator = validateMaxLength(200);
const addressCityFieldValidator = validateMaxLength(100);
const addressRegionFieldValidator = validateMaxLength(100);
const addressPostcodeValidator = composeValidators(validateRequired, validateLength(1, 20), validateRegex(/^[A-Za-z][A-Za-z0-9 ]+[A-Za-z]$/, 'Please enter a valid postcode'));
const countryIso2Validator = composeValidators(validateRequired, validateRegex(/^[A-Z]{2}$/, 'Please enter a valid 2-character country code'));
const termsAcceptedValidator = validateTrue('You must agree to the terms and conditions');
const privacyPolicyAcceptedValidator = validateTrue('You must confirm that you agree to the privacy policy');

function parseQueryString() : {email?: string, firstName?: string, surname?: string} {
    const parsed = qs.parse(window.location.search.slice(1));

    return {
        email: Array.isArray(parsed.email) ? parsed.email[0] : parsed.email,
        firstName: Array.isArray(parsed.firstname) ? parsed.firstname[0] : parsed.firstname,
        surname: Array.isArray(parsed.surname) ? parsed.surname[0] : parsed.surname,
    }
}

type FormContentProps = SignUpFormProps & {
    submitting: boolean
}
const FormContent: React.FC<FormContentProps> = React.memo((
    {
        submitting,
        showCountry = true,
        defaultCountryIso2,
        regionLabel = "County",
        postalCodeLabel = "Postcode",
        instructionText,
        smallPrint,
        cohortIds,
        isBasicForm,
        submitButtonText = 'Order my Pippa',
    }) => {
    const config = React.useContext(ConfigContext);
    const cohortIdsString = cohortIds.join(",");
    const cohortIdsValidator = React.useMemo(() => validateEqualTo(cohortIdsString), [cohortIdsString]);

    const parsedQs = parseQueryString();

    return (
        <>
            <PippaFormField visible={false} label={"Cohort Ids *"} wrapperClassName={'col-12'} name={"cohortIds"} defaultValue={cohortIdsString} validate={cohortIdsValidator} />
            <Box className={'row'}>
                <Box className={'col-12'}>
                    <Typography variant={'body1'}>{instructionText}</Typography>
                </Box>
            </Box>
            <Box className={'row'}>
                {isBasicForm ? (
                    <Box className={'col-12'}>
                        <Box className={'row'}>
                            <PippaFormField wrapperClassName={'col-12'} label="Email *" name="email" validate={emailFieldValidator} />
                            <PippaFormField wrapperClassName={'col-12 col-lg-6'} label="First name(s) *" name="givenName" validate={nameFieldValidator} />
                            <PippaFormField wrapperClassName={'col-12 col-lg-6'} label="Surname *" name="familyName" validate={nameFieldValidator} />
                        </Box>
                    </Box>
                ) : (
                    <>
                    <Box className={'col-12 col-md-6 col-xl-8'}>
                        <Box className={'row'}>
                            <PippaFormField initialValue={parsedQs.email} wrapperClassName={'col-12'} label="Email *" name="email" validate={emailFieldValidator} helperText={'You must sign up using the same email address which you used to enter the competition'}/>
                            <PippaFormField wrapperClassName={'col-12'} label="Password *" name="password" validate={passwordFieldValidator} type={'password'} helperText={'Please create a password for your Pippa. You will use this later if you want to set up your Pippa account, for example to add phone numbers of people to receive alerts from your Pippa.'}/>
                            <PippaFormField initialValue={parsedQs.firstName} wrapperClassName={'col-12 col-xl-6'} label="First name(s) *" name="givenName" validate={nameFieldValidator} />
                            <PippaFormField initialValue={parsedQs.surname} wrapperClassName={'col-12 col-xl-6'} label="Surname *" name="familyName" validate={nameFieldValidator} />
                            <PippaFormField renderField={(label, isError, input) => (
                                <MuiPhoneNumber
                                    defaultCountry={defaultCountryIso2?.toLowerCase()}
                                    preferredCountries={defaultCountryIso2 ? [defaultCountryIso2.toLowerCase()] : undefined}
                                    disableAreaCodes={true}
                                    countryCodeEditable={false}
                                    label={label} style={{width: '100%'}} error={isError} {...input}/>
                            )} wrapperClassName={'col-12 col-xl-6'} label="Phone number *" name="phoneNumber" validate={phoneNumberFieldValidator} helperText={'Please do not include the 0 at the start of your phone number.'}/>
                        </Box>
                    </Box>
                    <Box className={'col-12 col-md-6 col-xl-4'}>
                        <Box className={'row'}>
                            <PippaFormField wrapperClassName={'col-12'} label="Address line 1 *" name="address.line1" validate={addressLine1FieldValidator} helperText={'This is the address we will post your Pippa to'}/>
                            <PippaFormField wrapperClassName={'col-12'} label="Address line 2" name="address.line2" validate={addressLine2FieldValidator}/>
                            <PippaFormField wrapperClassName={'col-12'} label="Address line 3" name="address.line3" validate={addressLine3FieldValidator}/>
                            <PippaFormField wrapperClassName={'col-12'} label="City" name="address.city" validate={addressCityFieldValidator}/>
                            <PippaFormField wrapperClassName={'col-12'} label={regionLabel} name="address.region" validate={addressRegionFieldValidator}/>
                            <PippaFormField wrapperClassName={'col-12'} label={postalCodeLabel + " *"} name="address.postalCode" validate={addressPostcodeValidator} />
                            <PippaFormField renderField={(label, isError, input) => <CountrySelector label={label} isError={isError} input={input}/>} visible={showCountry} wrapperClassName={'col-12'} label="Country *" name="address.countryIso2" defaultValue={defaultCountryIso2?.toUpperCase()} validate={countryIso2Validator} />
                        </Box>
                    </Box>
                </>
                )}
            </Box>
            <Box className={'row'}>
                <Box className={'col-12 col-md-6 col-xl-8'}>
                    <Typography variant={'caption'}>
                        {smallPrint}
                    </Typography>
                </Box>
                <Box className={'col-12 col-md-6 col-xl-4'}>
                    <PippaFormField wrapperClassName={'col-12'} label={<>
                            I consent to <Link target={"_blank"} href={`${config.PIPPA_WEBSITE_URL}/conditions`}>the Terms and Conditions</Link>
                            </>} name="termsAccepted" validate={termsAcceptedValidator as any} type={'checkbox'}/>
                    <PippaFormField wrapperClassName={'col-12'} label={<>
                            I accept the <Link target={"_blank"} href={`${config.PIPPA_WEBSITE_URL}/privacy`}>Privacy Policy</Link>
                            </>} name="privacyAccepted" validate={privacyPolicyAcceptedValidator as any} type={'checkbox'}/>
                </Box>
            </Box>
            <Box className={'row justify-content-end'}>
                <Box className={isBasicForm ? 'col-12' : 'col-12 col-md-6 col-xl-4'}>
                    <Button
                        type={'submit'}
                        variant={'contained'}
                        style={{width: '100%'}}
                        disabled={submitting}
                        startIcon={submitting ? <CircularProgress size={20}/> : undefined}
                    >
                        {submitButtonText}
                    </Button>
                </Box>
            </Box>
        </>
    )
});

export default GenericSignUp;