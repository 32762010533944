import React from 'react';
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { ConfigContext } from "../config";


const SubmitSuccessBasic: React.FC = () => {
    const config = React.useContext(ConfigContext);

    return (
        <>
            <Typography>Thanks for your interest in Pippa! We'll use the email address you provided to let you know whether you have been selected for a free Pippa.</Typography>
            <Typography>In the meantime, you can head over to <Link href={config.PIPPA_WEBSITE_URL} target="_blank">{config.PIPPA_WEBSITE_URL}</Link> for more information about Pippa</Typography>
        </>
    )
}

export default SubmitSuccessBasic;
