import React from "react";
import {checkPasswordHelper} from "./validation";
import FormHelperText from "@material-ui/core/FormHelperText";

type PasswordHelperTextProps = {
    password: string;
}
const PasswordHelperText: React.FC<PasswordHelperTextProps> = ({password}) => {
    const conditions = checkPasswordHelper(password);
    return (
        <>
            {conditions.map((condition, i) => (
                <FormHelperText key={condition.text} margin={'dense'} style={{color: condition.satisfied ? 'green' : 'red'}}>
                    {(condition.satisfied ? '\u2713' : '\u2716') + ' ' + condition.text}
                </FormHelperText>
            ))}
        </>
    )
}

export default PasswordHelperText;
