import React from 'react';
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { ConfigContext } from "../config";


const SubmitSuccess: React.FC = () => {
    const config = React.useContext(ConfigContext);

    return (
        <>
            <Typography>Congratulations, you've signed up for a free Pippa! We'll drop you an email when it's on the way to the address you provided.</Typography>
            <Typography>In the meantime, you can head over to <Link href={config.PIPPA_WEBSITE_URL} target="_blank">{config.PIPPA_WEBSITE_URL}</Link> for more information about Pippa</Typography>
            <Typography>You can log into your account at any time at <Link href={config.PIPPA_ACCOUNT_URL} target="_blank">{config.PIPPA_ACCOUNT_URL}</Link></Typography>
        </>
    )
}

export default SubmitSuccess;
