import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

type SubmitErrorProps = {
    submitError: any;
}
const SubmitError: React.FC<SubmitErrorProps> = ({submitError}) => {
    const [open, setOpen] = React.useState(false);

    const handleClose = React.useCallback(() => setOpen(false), []);

    React.useEffect(() => {
        if (submitError) {
            setOpen(true);
        }
    }, [submitError])

    return (
        <Snackbar open={open} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
                {submitError}
            </Alert>
        </Snackbar>
    )
}

export default SubmitError;