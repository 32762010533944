import {Field, FieldInputProps, FieldProps, FieldRenderProps} from "react-final-form";
import { useTheme } from '@material-ui/core/styles';
import React from "react";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import PasswordHelperText from "./PasswordHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";


export interface PippaFormFieldProps extends FieldProps<string, FieldRenderProps<string>> {
    label: React.ReactNode
    helperText?: string
    wrapperClassName: string
    visible?: boolean
    renderField?: (label: React.ReactNode, isError: boolean | undefined, input: FieldInputProps<string, HTMLElement>) => React.ReactElement
}
const PippaFormField: React.FC<PippaFormFieldProps> = React.memo<PippaFormFieldProps>(({label, helperText, wrapperClassName, visible = true, renderField, ...props}) => {
    const theme = useTheme();

    return (
        <Field {...props}>
            {({input, meta}) => {
                const isError = meta.invalid && meta.touched;
                const helperTextFull: string = (isError ? meta.error : helperText !== undefined ? helperText: ' ');
                const helperTextRows: string[] = helperTextFull.split('\n');
                return (
                    <Box className={wrapperClassName} display={visible === false ? 'none' : undefined}>
                        {
                            renderField !== undefined ? renderField(label, isError, input) : input.type === 'checkbox' ? (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={input.name}
                                            color="primary"
                                            style={{color: isError ? theme.palette.error.main : undefined}}
                                            {...input}
                                        />
                                    }
                                    label={<Typography color={isError ? "error" : undefined}>{label}</Typography>}
                                />
                            ) : (
                                <TextField id={input.name} label={label} style={{width: '100%'}} error={isError} {...input} />
                            )
                        }
                        {helperTextRows.map((row, i) => <FormHelperText key={i} error={isError} margin={'dense'}>{row}</FormHelperText>)}
                        {input.type === 'password' && <PasswordHelperText password={input.value} />}
                    </Box>
                )
            }}
        </Field>
    )
});

export default PippaFormField;