import React from 'react';
import GenericSignUp from "./GenericSignUp";

const SAGA_INSTRUCTION_TEXT = "Thank you for signing up to receive a free Pippa Stove Monitor from Saga Insurance. Please complete the details below and we will send you your Pippa."
const SAGA_SMALL_PRINT = "We will store these details securely. We will use them for the purposes of managing your Pippa account, emailing you instructions, and sending you your free Pippa only. Your phone number will be used to receive alerts, you will be able to add additional numbers later. We will not use these details for marketing. We will not share them with anyone else. We will delete them if you close your Pippa account."
export const SagaSignUp: React.FC = () => {
    return (
        <GenericSignUp
            layoutProps={{imageAlt: 'Saga logo', imageSrc: '/images/saga_logo.png'}}
            regionLabel="County"
            postalCodeLabel="Postcode"
            defaultCountryIso2={'GB'}
            showCountry={false}
            instructionText={SAGA_INSTRUCTION_TEXT}
            smallPrint={SAGA_SMALL_PRINT}
            cohortIds={["saga"]}
        />
    );
}

export const SagaCompetitionSignUp: React.FC = () => {
    return (
        <GenericSignUp
            layoutProps={{imageAlt: 'Saga logo', imageSrc: '/images/saga_logo.png'}}
            isBasicForm={true}
            instructionText={'Thank you for your interest in Pippa – the amazing new fire prevention invention – and intelligent kitchen assistant. Complete your details to have the chance of getting one of 200 FREE Pippas!'}
            smallPrint={'We won’t spam you – and we will never share your contact details!'}
            cohortIds={["saga"]}
            submitButtonText={"Yes please!"}
        />
    )
}

const GENERAL_INSTRUCTION_TEXT = "Complete your details below to sign up to Pippa"
const GENERAL_SMALL_PRINT = "We will store these details securely. We will use them for the purposes of managing your Pippa account, emailing you instructions, and sending you your Pippa only. Your phone number will be used to receive alerts, you will be able to add additional numbers later. We will not use these details for marketing. We will not share them with anyone else. We will delete them if you close your Pippa account."
export const GeneralSignUp: React.FC = () => (
    <GenericSignUp
        regionLabel={"County/State"}
        postalCodeLabel={"Postcode/ZIP"}
        defaultCountryIso2={'GB'}
        instructionText={GENERAL_INSTRUCTION_TEXT}
        smallPrint={GENERAL_SMALL_PRINT}
        cohortIds={[]}
    />
)
