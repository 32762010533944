import React from 'react';
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Layout from "./Layout";


const NotFound: React.FC = () => {
    return (
        <Layout>
            <Box className={'row'}>
                <Box className={'col'}>
                    <Typography align={'center'} variant={'h3'}>This sign up page was not found</Typography>
                </Box>
            </Box>
        </Layout>
    )
}

export default NotFound;
