import Box from "@material-ui/core/Box";
import React from "react";


export type LayoutProps = {
    imageAlt?: string
    imageSrc?: string;
}
const Layout: React.FC<LayoutProps> = ({imageAlt = 'Logo', imageSrc, children}) => {
    return (
        <Box className={'p-0 p-md-3'} minHeight={'100vh'} bgcolor={'#909090'} alignItems={'center'} justifyContent={'center'} display={'flex'}>
            <Box boxShadow={5} className={'container-xl p-3'}bgcolor={'#bdbdbd'}>
                <Box className={'row justify-content-center'}>
                    <Box className={'col-6 col-sm-4 col-md-3'}>
                        <Box className={'row align-items-center'} style={{height: '100%'}}>
                            <Box className={'col-12'}>
                                <Box className={'row justify-content-center'}>
                                    <img alt={'Pippa logo'} src={'/images/logo_red.png'} className={'headerLogo align-self-center'}/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={'col-6 col-sm-4 col-md-3'}>
                        <Box className={'row align-items-center'} style={{height: '100%'}}>
                            <Box className={'col-12'}>
                                <Box className={'row justify-content-center'}>
                                    <img alt={'Pippa render'} src={'/images/pippa_render_2.png'} className={'headerLogo align-self-center'}/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    {imageSrc && <Box className={'col-12 col-sm-4 col-md-3'}>
                        <Box className={'row align-items-center'} style={{height: '100%'}}>
                            <Box className={'col-12'}>
                                <Box className={'row justify-content-center'}>
                                    <img alt={imageAlt} src={imageSrc} className={'headerLogo align-self-center'}/>
                                </Box>
                            </Box>
                        </Box>
                    </Box>}
                </Box>
                {children}
            </Box>
        </Box>
    )
}

export default Layout;
